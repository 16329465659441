<template>
  <NotificationsMB v-if="layout == 4" />
  <NotificationsPC v-else />
</template>

<script>
import NotificationsPC from "@/pages/pc/notifications/Notifications";
import NotificationsMB from "@/pages/mb/notifications/Notifications";
import { provide, reactive } from "vue";
import NotificationService from "@/services/notification";
import EventService from "@/services/event";
import PromotionService from "@/services/promotion";
import BrandServices from "@/services/brand";
import { serialize } from "@/utlis/serialize";
export default {
  components: {
    NotificationsPC,
    NotificationsMB,
  },
  setup() {
    const state = reactive({
      oldEvents: [],
      newEvents: [],
      promotions: [],
      events: [],
      brands: [],
      loading: true,
    });

    const currentTime = Math.round(new Date().getTime() / 1000);

    getNotification();
    getPromotions();
    getEvents();
    getBrands();

    async function getNotification() {
      const response = await NotificationService.getNotification();
      if (response.data) {
        const events = response.data.events;
        for (const key in events) {
          if (parseInt(events[key].time_end) > currentTime) {
            state.newEvents.push(events[key]);
          } else {
            state.oldEvents.push(events[key]);
          }
        }

        state.loading = false;
      }
    }

    async function getEvents() {
      const response = await EventService.fetchOriginEvent();
      if (response.data) {
        state.events = response.data.events;
      }
    }

    async function getPromotions() {
      const response = await PromotionService.fetchPromotions();
      if (response.data) {
        state.promotions = response.data.promotions;
      }
    }

    async function getBrands() {
      const response = await BrandServices.fetchBrands(
        serialize({
          limit: 10,
          use_caching: true,
          get_banner: true,
        })
      );

      if (response.data) {
        state.brands = response.data.brands;
      }
    }

    provide("state", state);
  },
};
</script>
