<template>
    <div class="notification-page">
        <header>
            <div class="page-header" id="dosiin_page-header">
                <div class="header-inner dosiin_header-inner">
                    <ul class="category-list">
                        <li class="category-item dosiin_category-item active" dosiin-data="notifications"> 
                            <a href="#" title="Thông báo">Thông báo</a>
                        </li>
                        <li class="category-item-divider"></li>
                        <li class="category-item dosiin_category-item inactive" dosiin-data="support">
                            <a href="https://m.me/dosiinvietnam" target="_blank" title="Hỗ trợ">Hỗ trợ</a>
                        </li>
                    </ul>
                    <div class="group-search-cart">
                        <!-- {include file="v4/common/icon_search_cart_header.tpl"} -->
                    </div>
                </div>
            </div>
            <div class="sticky-header header-notifications" id="dosiin_header-sticky">
                <div class="header-left">
                    <ul class="category-list">
                        <li class="category-item dosiin_category-item active" dosiin-data="notifications">
                            <a href="?category=notifications" title="Thông báo">Thông báo</a>
                        </li>
                        <li class="category-item-divider"></li>
                        <li class="category-item dosiin_category-item inactive" dosiin-data="support"> 
                            <a href="https://m.me/dosiinvietnam" title="Hỗ trợ">Hỗ trợ</a>
                        </li>
                    </ul>
                </div>
                <div class="header-right">
                    <!-- {include file="v4/common/icon_search_cart_header.tpl" wrapper=false} -->
                </div>
            </div>
        </header>
        
        <div class="wrapper-notification">
            <router-link class="dosiin_btn-open-modal notification-description_wrapper dosiin_w-100" :to="{name : 'notifications-event'}">
                <div class="notification-description_img notification-item_img-wrapper">
                    <img class="name-notification" width="36" height="36" :src="_event" alt="Sự kiện">
                </div>
                <div class="notification-description-main-page_info">
                    <h5 class="notification-item_title">Sự kiện <span>({{state.events.length}})</span></h5>
                    <dl class="notification-item_title-sub" v-if="state.events.length > 0">
                        <dd v-text="state.events[0].name"></dd>
                    </dl>
                </div>
                <div class="notification-see-more">
                    <i class="dsi dsi-arrow-forward"></i>
                </div>
            </router-link>

            <router-link class="dosiin_btn-open-modal notification-description_wrapper dosiin_w-100" :to="{ name : 'notifications-promo' }">
                <div class="notification-description_img notification-item_img-wrapper">
                    <img class="name-notification" width="36" height="36" :src="_promotion" alt="event" />
                </div>
                <div class="notification-description-main-page_info">
                    <h5 class="notification-item_title">Khuyến mãi <span>({{state.promotions.length}})</span></h5>
                    <dl class="notification-item_title-sub" v-if="state.promotions.length > 0">
                        <dd>{{state.promotions[Object.keys(state.promotions)[0]].name}}</dd>
                    </dl>
                </div>
                <div class="notification-see-more">
                    <i class="dsi dsi-arrow-forward"></i>
                </div>
            </router-link>

            <a class="notification-description_wrapper" href="/products-shock" title="Xem thêm">
                <div class="notification-description_img notification-item_img-wrapper">                
                    <img class="name-notification" width="36" height="36" src="https://dosi-in.com/images/assets/icons/notification-brands.png" alt="brands">            
                </div>
                <div class="notification-description-main-page_info">
                    <h5 class="notification-item_title">
                        Thương hiệu 
                        <!-- <span>(13)</span> -->
                    </h5>
                    <dl class="notification-item_title-sub">
                        <!-- <dd>SUMMER VIBES!</dd> -->
                    </dl>
                </div>
                <div class="notification-see-more">                
                    <i class="dsi dsi-arrow-forward"></i>            
                </div>
            </a>
        </div>
        <div class="brand-sub-page-section">
            <div class="brand-suggested-brands">
                <div class="brand-list-header header-section">            
                    <h2 class="brand-suggested-brands_title">Thương hiệu bạn có thể biết</h2>            
                    <div class="view-all">                
                        <router-link class="primary-button" :to="{name : 'brand'}">                    
                            <span class="primary-link_text">Toàn bộ</span>                    
                            <i class="dsi dsi-act-arrow-forward"></i>                
                        </router-link>            
                    </div>        
                </div>
                <div class="dosiin_suggested-brands_slider suggested-brands_slider">
                    <Swiper 
                        :slidesPerView="1.5"
                        :spaceBetween="10"
                    >
                        <SwiperSlide class="swiper-slide dosiin_brand-follow-wrapper" v-for="(brand , i ) in state.brands" :key="i">
                            <BrandItemVertical :brand="brand" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';

    export default {
        components:{
            BrandItemVertical
        },
        setup() {
    
            const state = inject('state');

            return{ 
                state
            }
        },
    }
</script>