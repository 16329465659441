<template>
    <div class="dosiin_page-min-height dosiin_padding-header-top">
        <div class="ds_notification-inner">
            <div class="ds_notification-right">
                <div class="ds_notification-box">
                    <div class="ds_notification-navi">
                        <p>Thông báo </p>
                        <div class="ds_notification-dot"></div>
                    </div>
                    <div class="ds_notification-scrolly d_noti-txtbox">
                        <div class="ds_notification-scrolly-content">
                            <div class="wrapper-notification">
                                <div class="wrapper-notification-list">
                                    <div class="wrapper-notification-item">
                                        <div class=" notification-description_wrapper-color-event">
                                            <router-link :to="{name : 'notifications-event'}" class="notification-description_wrapper">
                                                <div class="notification-description_img notification-item_img-wrapper">
                                                    <img class="name-notification" width="36" height="36" :src="_event" alt="event">
                                                </div>
                                                <div class="notification-description-main-page_info">
                                                    <router-link :to="{name : 'notifications-event'}">
                                                        <h5 class="notification-item_title">Sự kiện <span>({{state.events.length}})</span></h5>
                                                    </router-link>
                                                    <dl class="notification-item_title-sub" v-if="state.events.length > 0">
                                                        <dd v-text="state.events[0].name"></dd>
                                                    </dl>
                                                </div>
                                                <div class="notification-see-more">
                                                    <router-link :to="{name : 'notifications-event'}">
                                                        <i class="dsi dsi-arrow-forward"></i>
                                                    </router-link>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                            <div class="wrapper-notification-item">
                                <div class=" notification-description_wrapper-color-promotion">
                                    <router-link :to="{name : 'notifications-promo'}" class="notification-description_wrapper">
                                        <div class="notification-description_img notification-item_img-wrapper">
                                            <img class="name-notification" width="36" height="36" :src="_promotion">
                                        </div>
                                        <div class="notification-description-main-page_info">
                                            <router-link :to="{name : 'notifications-promo'}">
                                                <h5 class="notification-item_title">Khuyến mãi <span>({{state.promotions.length}})</span></h5>
                                            </router-link>
                                            <dl class="notification-item_title-sub" v-if="state.promotions.length > 0">
                                                <dd>{{state.promotions[Object.keys(state.promotions)[0]].name}}</dd>
                                            </dl>
                                        </div>
                                        <div class="notification-see-more">
                                            <router-link :to="{name : 'notifications-promo'}">
                                                <i class="dsi dsi-arrow-forward"></i>
                                            </router-link>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
    
            <div class="ds_notification-left">
                <div class="brand-list-header header-section">
                    <h3 class="brand-suggested-brands_title">Thương hiệu bạn có thể biết</h3>
                    <div class="view-all">
                        <router-link class="primary-button" :to="{ name : 'brand'}" title="Xem tất cả thương hiệu">
                            <span class="primary-link_text">Toàn bộ</span>
                            <i class="dsi dsi-act-arrow-forward"></i>
                        </router-link>
                    </div>
                </div>
                <div class="dosiin_suggested-brands_slider suggested-brands_slider">
                    <ul class="ds_notification-left_list">
                        <li class="ds_notification-left_item" v-for="(brand,i ) in state.brands.slice(0,2)" :key="i">
                            <BrandItemVertical :brand="brand" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    export default {
        components :{
            BrandItemVertical
        },
        setup() {
    
            const state = inject('state');

            return{ 
                state
            }
        },
    }
</script>